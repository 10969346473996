<script>
  import Hls from "hls.js";
  export default {
    data: function () {
      return {
        videos: {
          en: {
            videoChunks: "https://cdn.models1a.com/videosChunks/Video_registro_destacado_EN_SUB/Video_registro_destacado_EN_SUB.m3u8",
            videoFalls: "https://cdn.models1a.com/videosChunks/Video_registro_destacado_EN_SUB/Video_registro_destacado_EN_SUB.mp4",
          },
          es: {
            videoChunks: "https://cdn.models1a.com/ejemploVideo/videoExample.m3u8",
            videoFalls: "https://cdn.models1a.com/ejemploVideo/input_video.mp4",
          },
        },
        loadVideo: true,
        videoId: null,
        lang: "",
      };
    },
    props: ["videoModelFiles"],
    methods: {
      loadingLogo() {
        this.loadVideo = !this.loadVideo;
      },
      loadChunks() {
        const hls = new Hls();
        if (Hls.isSupported()) {
          hls.loadSource(this.videos[this.lang].videoChunks);
          hls.attachMedia(this.videoId);
          videoId.play();
          return;
        }
        this.videoId.src = this.videos[this.lang].videoFalls;
      },
      loadNormalVideo() {
        this.videoId.src = this.videoModelFiles;
        this.videoId.play();
      },
    },
    computed: {
      sourceVideo() {
        return this.videoModelFiles;
      },
    },
    mounted: function () {
      this.lang = localStorage.getItem("user-language") || "es";
      this.videoId = document.querySelector("#videoId");
      document.body.style.overflow = "hidden";
      if (!this.videoModelFiles) {
        this.loadChunks();
        return;
      }
      this.loadNormalVideo();
    },
    beforeDestroy() {
      document.body.style.overflow = "";
      this.videoId.pause();
    },
  };
</script>

<template>
  <div :class="{ modalvideoexample: true, loading: loadVideo }">
    <div class="modalvideoexample__wrapper">
      <div class="modalvideoexample__circle" @click="$emit('changeStatus')" v-if="!loadVideo">
        <iconic class="modalvideoexample__closeicon" name="closeIcon" />
      </div>
      <video v-show="!loadVideo" @loadeddata="loadingLogo" class="modalvideoexample__video" controls playsinline webkit-playsinline id="videoId"></video>
    </div>
  </div>
</template>

<style lang="scss">
  .modalvideoexample {
    @include Flex(row, center, center);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 201;
    background: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(4.5px);
    &__wrapper {
      @include Flex(column, center, end);
      position: relative;
      width: 90%;
    }
    &__video {
      width: 100%;
      border-radius: $radius-20;
      max-height: 70vh;
      max-width: 400px;
      object-fit: cover;
      margin: auto;
    }
    &__closeicon {
      color: #fff;
      font-size: 13px;
    }
    &__circle {
      @include Flex(row, center, center);
      width: 35px;
      height: 35px;
      background: #2e2c2c77;
      border-radius: 100%;
      cursor: pointer;
      position: absolute;
      top: -40px;
      z-index: 1;
    }
    @media screen and (min-width: 768px) {
      &__wrapper {
        width: fit-content;
      }
    }
  }
</style>
