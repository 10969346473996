<script>
  export default {
    name: "Turnstile",
    props: {
      siteKey: {
        type: String,
        required: true,
      },
      theme: {
        type: String,
        default: "light",
      },
      size: {
        type: String,
        default: "normal",
      },
      callback: {
        type: Function,
        default: () => {},
      },
      errorCallback: {
        type: Function,
        default: (error) => {
          console.error(error);
        },
      },
      expiredCallback: {
        type: Function,
        default: null,
      },
      unsupportedCallback: {
        type: Function,
        default: null,
      },
      timeoutCallback: {
        type: Function,
        default: null,
      },
    },
    data() {
      return {
        turnstileInstance: null,
      };
    },
    methods: {
      renderTurnstile() {
        if (!window.turnstile) {
          this.errorCallback("Turnstile is not loaded yet.");

          return;
        }

        this.turnstileInstance = window.turnstile.render(this.$refs.turnstileContainer, {
          sitekey: this.siteKey,
          theme: this.theme,
          size: this.size,
          callback: this.callback,
          "error-callback": this.errorCallback,
          "expired-callback": this.expiredCallback ? this.expiredCallback : this.resetTurnstile,
          "unsupported-callback": this.unsupportedCallback ? this.unsupportedCallback : this.errorCallback,
          "timeout-callback": this.timeoutCallback ? this.timeoutCallback : this.resetTurnstile,
        });
      },

      resetTurnstile() {
        if (this.turnstileInstance) {
          window.turnstile.reset(this.$refs.turnstileContainer);
        }
      },

      removeTurnstile() {
        if (this.turnstileInstance) {
          window.turnstile.remove(this.$refs.turnstileContainer);
          this.turnstileInstance = null;
        }
      },

      loadTurnstileScript() {
        const src = "https://challenges.cloudflare.com/turnstile/v0/api.js";

        if (!window.turnstile || !document.querySelector(`script[src="${src}"]`)) {
          const script = document.createElement("script");
          script.src = src;
          script.async = true;

          script.onload = () => {
            this.renderTurnstile();
          };

          script.onerror = () => {
            this.errorCallback("Failed to load Turnstile script.");
          };

          document.head.appendChild(script);
        } else {
          this.renderTurnstile();
        }
      },
    },

    mounted() {
      this.$nextTick(() => {
        this.loadTurnstileScript();
      });
    },

    beforeDestroy() {
      this.removeTurnstile();
    },
  };
</script>

<template>
  <div class="turnstileContainer">
    <div class="turnstileContainer__captcha" ref="turnstileContainer" />
  </div>
</template>

<style lang="scss">
  .turnstileContainer {
    @include Flex(row, center, center);
    gap: $space-8;
    margin: 0 15px;
    border-radius: $radius-12;
    &__refresh {
      padding: $space-12;
      border-radius: 10px;
      font-size: 25px;
      cursor: pointer;
      color: #262626;
      transition: all ease-in-out 300ms;

      &:hover {
        background-color: $lightgray;
      }
    }
    iframe {
      width: 70%;
    }
    @media (min-width: 768px) {
      iframe {
        width: 100%;
      }
    }
  }
</style>
